@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color:#fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif,'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181818;
}


.container{
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 250px;
  background: hsla(0, 0%, 100%, 0.034);
  border-color: rgba(49, 49, 49, 0.98);
  border-width: 2px;
  border-style: solid;
  padding: 30px;
  border-radius: 10px;
}

h2 {
  padding-bottom: 4px;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: rgba(49, 49, 49, 0.98);;
  border-style: solid;
  border-width: 0;
  padding-bottom: 3px;
  border-bottom-width: 2px;
}

.btn {
  display: inline-block;
  border:0px;
  padding: 10px 20px;
  margin: 5px;
  color:#181818;
  background-color: #fff;
  font-size: 15px;
  font-family: inherit;
  border-radius: 5px;
  cursor: pointer;
  transition: .4s;
}

.btn:hover, .btn:focus{
  background: rgba(124, 246, 255, 0.74);
  color: #fff;
  border: 10px;
  /* background: rgba(255, 255, 255, 0.781) */
}

.btn-block {
  display: block;
  width: 100%;
  margin: 0;
  margin-top: 20px;
}

.input_field{
  position: relative;
  font-family: inherit;
  font-size: large;
  text-align: center;
  color: #181818;
  display: block;
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  border: none;
  transition: .4s;
}

.input_field:-ms-input-placeholder{
  color:rgb(80, 80, 80);
  opacity: 1;
  -ms-transition: .4s;
  transition: .4s;
}

.input_field::placeholder{
  color:rgb(80, 80, 80);
  opacity: 1;
  transition: .4s;
}

.input_field:hover{
  background: rgba(255, 255, 255, 0.781)
}

.input_field:active,.input_field:focus {
  height: 38px;
}

.input_field:hover:-ms-input-placeholder{
  color:rgb(41, 41, 41);
}

.input_field:hover::placeholder{
  color:rgb(41, 41, 41);
}

.input_form{
  position: relative;
  padding-bottom: 45px;
}

.input_div {
  display: flexbox; 
  justify-content: space-between;
  padding-top: 20px;
  min-height: 50px;
}

.urlP{
  border-top: rgba(49, 49, 49, 0.98);;
  border-style: solid;
  border-width: 0;
  padding-top: 25px;
  border-top-width: 2px;
  display: flex;
  justify-content:space-around;
  font-weight: 600;
  font-size: large;
}
